<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex">
       
       <!-- <div class="backbox">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Account
       </h4>
     </div>
      <!-- <div class="dispflex" >
     
         <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
      </div> -->
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <!-- <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-book-reader"></i
                ></span>
                <h1>School Account</h1>
               
              </div>
              <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
            </div> -->
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input type="radio" name="tabs" id="academictab" :checked="!checkPermission('check') ? 'checked' : ''" />
                <label for="academictab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Subscription</label>
                <div class="tab">
                  <plan-comp />
                </div>

                <input
                  type="radio"
                  name="tabs"
                  id="departtab"
                  :checked="checkPermission('check') ? 'checked' : ''"
                  v-if="checkPermission('check')"
                />
                <label for="departtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" v-if="checkPermission('check')">Settings </label>
                <div class="tab" v-if="checkPermission('check')">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="admintbl mt-1 p-3">
                        <table border="1">
                          <thead>
                            <tr>
                              <th scope="col" rowspan="2">
                                Field Name<span class="required">*</span>
                              </th>
                              <th scope="col" rowspan="2">
                                Prefix<span class="required">*</span>
                              </th>
                              <th scope="col" rowspan="2">
                                Format<span class="required">*</span>
                              </th>
                              <th scope="col" rowspan="2">
                                Starting Number<span class="required">*</span>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Department Id"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="department.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="department.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="department.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.crtfeeplan
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Roll No.allocated"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="student.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="student.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="student.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.editstudentAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Teacher Id"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="teacher.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="teacher.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="teacher.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.editeacherAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr v-if="checkFeesPlanPermission('certif')" >
                              <td data-label="Fee Name">
                                <input
                                  value="Fee Type Id"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="feeType.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="feeType.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="feeType.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.edifeeTypeAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr v-if="checkFeesPlanPermission('certif')">
                              <td data-label="Fee Name">
                                <input
                                  value="Invoice Id"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="invoice.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="invoice.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="invoice.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.editInvoiceAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr v-if="checkCertificateRequestPermission('certif')">
                              <td data-label="Fee Name">
                                <input
                                  value="Certificate Application No"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="transfer.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="transfer.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="transfer.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.TCAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr v-if="checkCertificateRequestPermission('certif')">
                              <td data-label="Fee Name" >
                                <input
                                  value="Transfer Certificate No"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="transfer.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="transfer.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="transfer.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.TCAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr v-if="checkCertificateRequestPermission('certif')">
                              <td data-label="Fee Name">
                                <input
                                  value="Conduct Certificate No"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="conduct.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="conduct.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="conduct.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.CCAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr v-if="checkCertificateRequestPermission('certif')">
                              <td data-label="Fee Name">
                                <input
                                  value="Bonafide Certificate No"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <input
                                  disabled
                                  v-model="bonafide.prefix"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="format">
                                <input
                                  disabled
                                  v-model="bonafide.format"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  v-model="bonafide.start"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.BCAutoNum
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Terms And Conditions"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix">
                                <input
                                  disabled
                                  :value="termsAndConditionDescription"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <!-- <td data-label="format">
                                <input
                                  disabled
                                  value="--"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  value="--"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td> -->
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.editTermsAndCondition
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Working Days"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix" style="text-align: center;">
                                {{  showWorkingDays  }}
                                <!-- <input
                                  disabled
                                  :value="termsAndConditionDescription"
                                  type="text"
                                  class="form-control form-input"
                                /> -->
                              </td>

                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  @click.prevent="openWorkingdays"
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Current AcademicYear"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix">
                                <select
                                  style="text-align: center"
                                  class="form-control form-input"
                                  id="deptlistid"
                                  disabled
                                  v-model="currentAcademicYear"
                                >
                                  <option :value="null">-- Select --</option>
                                  <option
                                    v-for="(item, index) in academicYearList"
                                    :key="index"
                                    :value="item._id"
                                  >
                                    {{ item.from }} - {{ item.to }}
                                  </option>
                                </select>
                                <!-- <input style="text-align: center;"
                                  v-model="currentAcademicYear"
                                  type="text"
                                  class="form-control form-input"
                                /> -->
                              </td>

                              <td>
                                <button
                                  v-b-modal.updateCurrentAcademicyr
                                  class="btn round orange tooltipt"
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="School support email"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix">
                                <input
                                  style="text-align: center"
                                  v-model="supportMail"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>

                              <td>
                                <button
                                  v-b-modal.updateSupportID
                                  class="btn round orange tooltipt"
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="School sender email"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix">
                                <input
                                  disabled
                                  style="text-align: center"
                                  v-model="senderMail"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>

                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.updateSenderID
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" data-label="Fee Name">
                                <input
                                  value="Enable Class Ranking"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="togBtn"
                                    @change="updateIsClassRanking"
                                    v-model="isClassRanking"
                                  />
                                  <div class="slider"></div>
                                </label>
                              </td>
                            </tr>
                            <!-- <tr>
                              <td colspan="4" data-label="Fee Name">
                                <input
                                  value="Enable Class Section Ranking"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="togBtn"
                                    @change="updateIsClassSectionRanking"
                                    v-model="isClassSectionRanking"
                                  />
                                  <div class="slider"></div>
                                </label>
                              </td>
                            </tr> -->
                            <tr>
                              <td colspan="4" data-label="Fee Name">
                                <input
                                  value="Send Invite Email after Student is created"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="togBtn"
                                    @change="updateTriggerMail"
                                    v-model="isMailTrigger"
                                  />
                                  <div class="slider"></div>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" data-label="Fee Name">
                                <input
                                  value="Signature of principal"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.viewSignFile
                                  v-if="signature.path"
                                >
                                  <i class="far fa-eye"></i
                                  ><span class="tooltiptext">View</span>
                                </button>
                                <button
                                  class="btn round red tooltipt"
                                  v-if="signature.path"
                                  @click="
                                    removeFile('signature', signature.key)
                                  "
                                >
                                  <i class="far fa-trash-alt"></i>
                                  <span class="tooltiptext">Delete</span>
                                </button>
                                <input
                                  v-else
                                  type="file"
                                  accept="image/*"
                                  ref="schoolSign"
                                  name="schoolSign"
                                  @change="
                                    updateFile('signature', 'schoolSign')
                                  "
                                />
                              </td>
                            </tr>
                            <tr v-if="checkCertificateRequestPermission('certif')">
                              <td colspan="4" data-label="Fee Name">
                                <input
                                  value="School Seal/Stamp"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="prefix">
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.viewSealFile
                                  v-if="schoolSeal.path"
                                >
                                  <i class="far fa-eye"></i
                                  ><span class="tooltiptext">View</span>
                                </button>
                                <button
                                  class="btn round red tooltipt"
                                  v-if="schoolSeal.path"
                                  @click="
                                    removeFile('schoolSeal', schoolSeal.key)
                                  "
                                >
                                  <i class="far fa-trash-alt"></i>
                                  <span class="tooltiptext">Delete</span>
                                </button>
                                <input
                                  v-else
                                  type="file"
                                  accept="image/*"
                                  ref="schoolSealStamp"
                                  name="schoolSealStamp"
                                  @change="
                                    updateFile('schoolSeal', 'schoolSealStamp')
                                  "
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="grandlogintab" v-if="checkPermission('check')"/>
                <label for="grandlogintab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" v-if="checkPermission('check')"> Grant Vidhyaan Access</label>

                <div class="tab" v-if="checkPermission('check')">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="admintbl mt-1 p-3">
                        <table border="1">
                          <thead>
                            <tr>
                              <th scope="col" rowspan="6">
                                Grant Access To
                              </th>
                              <th scope="col" rowspan="6">
                                Duration<span class="required">*</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-label="Grand Access To">
                                Vidhyaan Technical Support
                              </td>

                              <td data-label="Duration">
                                <span class="web-has-float-label">
                                  <select
                                    :disabled="isEnableGrantLoginAccess"
                                    v-model="duration"
                                    class="form-control form-input"
                                    id="durationId"
                                    placeholder="Duration"
                                  >
                                    <option :value="null">--Select--</option>
                                    <option
                                      v-for="(item, index) in loginDurations"
                                      :key="index"
                                      :value="item.value"
                                    >
                                      {{ item.name }}
                                    </option>
                                  </select>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <!-- <div class="col-lg-12"></div>
                    <div class="col-lg-2"> -->
                      <div class="col-lg-12">
                      <div
        class="pt-3 text-center dk_iconsml"
        style="border-top: 1px solid #e5e5e5"
      >
      <button
                          v-if="!isEnableGrantLoginAccess"
                          type="button"
                          :disabled="!duration"
                          class="btn btnsml"
                          @click.prevent="updateGrandLogin"
                        >
                          <span>Save</span>
                        </button>
                        <button style="width: 13%;"
                          v-if="isEnableGrantLoginAccess"
                          type="button"
                          class="btn btnsml"
                          @click.prevent="removeGrandLogin"
                        >
                          <span>Remove Access</span>
                        </button>
      </div>
    </div>
                    <!-- </div> -->
                  </div>
                </div>
                <input type="radio" name="tabs" id="holiday" v-if="checkPermission('check')" />
                <label for="holiday" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" v-if="checkPermission('check')"> Add Holidays</label>

                <div class="tab" v-if="checkPermission('check')">
                  <holiday></holiday>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="crtfeeplan" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closedepartmentidPopUp">×</button>

      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="departConfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="department.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('departConfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="department.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('departConfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="department.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('departConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('department')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="viewSignFile">
      <div style="text-align: center">
        <img
          :src="signature.path"
          alt="Avatar"
          class="mt-3"
          width="200"
          height="200"
        />
      </div>

      <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
        <!-- <div class="dk_icon"></div> -->
        <div class="text-center dk_iconsml">
          <button
            type="button"
            @click="closeFilePopUp"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Close</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="viewSealFile">
      <div style="text-align: center">
        <img
          :src="schoolSeal.path"
          alt="Avatar"
          class="mt-3"
          width="200"
          height="200"
        />
      </div>

      <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
        <div class="text-center dk_iconsml">
          <button
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
            @click="closeFilePopUp"
          >
               <span>Close</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="updateSenderID" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closeSenderMailPopUp">×</button>

      <div class="contact-form">
        <h2>Update School Sender mail</h2>
        <br />
        <div>
          <form>
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="editsenderMail"
                  />

                  <label for="Prefix">Sender mail </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                :disabled="!editsenderMail"
                @click.prevent="updateSenderMail"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeSenderMailPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="updateSupportID"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>Update school support mail</h2>
        <br />
        <div>
          <form>
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="editsupportMail"
                  />

                  <label for="Prefix">Support mail </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                :disabled="!editsupportMail"
                @click.prevent="updateSupportMail"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeSupportMailPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="updateCurrentAcademicyr"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form>
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="currentAcademicYear"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>

                  <label for="Prefix">Current AcademicYear </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
           <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                :disabled="!currentAcademicYear"
                @click.prevent="updateCurrentAcademicYear"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                :disabled="!currentAcademicYear"
                @click.prevent="closeupdateCurrentAcademicyr"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="editstudentAutoNum" style="width: 100%">
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="studentConfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="student.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('studentConfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="student.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('studentConfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="student.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('studentConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('student')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="editeacherAutoNum" style="width: 100%">
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="teacherConfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="teacher.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('teacherConfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="teacher.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('teacherConfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="teacher.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('teacherConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('teacher')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="edifeeTypeAutoNum" style="width: 100%">
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="feeConfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="feeType.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('feeConfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="feeType.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('feeConfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="feeType.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('feeConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('feeType')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="editInvoiceAutoNum" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closedepartmentidPopUp">×</button>

      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="editInvoiceAutoNumfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="invoice.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('editInvoiceAutoNumfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="invoice.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('editInvoiceAutoNumfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="invoice.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('editInvoiceAutoNumfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('invoice')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="TCAutoNum" style="width: 100%">
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="tcConfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="transfer.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('tcConfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="transfer.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('tcConfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="transfer.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('tcConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('TCrequest')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="CCAutoNum" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closedepartmentidPopUp">×</button>

      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="CCconfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="conduct.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('CCconfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="conduct.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('CCconfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="conduct.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('CCconfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('CCrequest')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="BCAutoNum" style="width: 100%">
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="BCconfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    v-model="bonafide.prefix"
                    placeholder="Prefix"
                  />
                  <label
                    v-if="errors.first('BCconfig.Prefix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Prefix is required and (Example: AI- or AI# )</label
                  >
                  <label v-else for="Prefix">Prefix </label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="Format"
                    v-validate="{
                      required: true,
                      regex: '^{[0-9]*}$',
                    }"
                    v-model="bonafide.format"
                    class="form-control form-input"
                    id="Format"
                    placeholder="Format"
                  />
                  <label
                    v-if="errors.first('BCconfig.Format')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Format is required and (Example: {0000} )
                  </label>
                  <label v-else for="Format">Format</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="number"
                    name="Start"
                    v-validate="'required'"
                    v-model="bonafide.start"
                    class="form-control form-input"
                    id="Start"
                    placeholder="Start"
                  />
                  <label
                    v-if="errors.first('BCconfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Start is required</label
                  >
                  <label v-else for="Start">Start </label>
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateDepartmentConfig('BCrequest')"
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closedepartmentidPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="editTermsAndCondition" style="width: 100%">
      <div class="contact-form">
        <h2>Update</h2>
        <br />
        <div>
          <form data-vv-scope="editInvoiceAutoNumfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-12 pr-md-3 mt-3">
                <span class="web-has-float-label">
                  <textarea
                    class="form-control web-form-input"
                    style="min-height: 135px; resize: none"
                    id="message"
                    v-model="termsAndConditionDescription"
                  ></textarea>

                  <label for="message"
                    >Terms And Conditions<span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                :disabled="!termsAndConditionDescription"
                class="btn btnsml"
                @click.prevent="saveTermsAndCondition"
              >
                <span>Save</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeTermsAndCondition"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="editWorkingDays" style="width: 100%" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeWorkingDay">×</button>
      <div class="contact-form">
        <h2>Update Working Days</h2>
        <br />
        <div>
          <form data-vv-scope="editInvoiceAutoNumfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="col-lg-6">
                
                <div >
                  <form data-vv-scope="department" accept-charset="utf-8">
                    <div
                      v-for="(value, index) in updateWorkingList"
                      :key="index"
                    >
                      <div class="form-row">
                        <div class="form-group input-group col-md-2 mt-3">
                          <label class="custcheckbox">
                            <input
                              type="checkbox"
                              v-model="value.isselected"
                              class="form-control"
                              id="deptid"
                            />
                            <span class="checkmarkchk"></span>
                          </label>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          {{ value.name }}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
               
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                @click.prevent="saveWorkingDays"
              >
                <span>Save</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeWorkingDay"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      viLoader: false,
      activeSubscriptions: [],
      department: {
        prefix: "",
        format: "",
        start: null,
      },
      teacher: {
        prefix: "",
        format: "",
        start: null,
      },
      student: {
        prefix: "",
        format: "",
        start: null,
      },
      feeType: {
        prefix: "",
        format: "",
        start: null,
      },
      invoice: {
        prefix: "",
        format: "",
        start: null,
      },
      transfer: {
        prefix: "",
        format: "",
        start: null,
      },
      conduct: {
        prefix: "",
        format: "",
        start: null,
      },
      bonafide: {
        prefix: "",
        format: "",
        start: null,
      },
      schoolSeal: {
        path: "",
        key: "",
      },
      signature: {
        path: "",
        key: "",
      },
      loginDurations: [
        {
          name: "1 Day",
          value: "1",
        },
        {
          name: "2 Days",
          value: "2",
        },
        {
          name: "3 Days",
          value: "3",
        },
      ],
      editsupportMail: "",
      editsenderMail: "",
      supportMail: "",
      senderMail: "",
      termsAndConditionDescription: "",
      duration: "",
      currentAcademicYear: null,
      isMailTrigger: false,
      isClassRanking: false,
      isClassSectionRanking: false,
      isEnableGrantLoginAccess: false,
      academicYearList: [],
      workingDayList: [],
      showWorkingDays: '',
      updateWorkingList: [],
      accountSettingId: null,
    };
  },
  created() {
    // this.getPlanDetails();
    this.getAcademicYearList();
    this.getAccountDetails();
    this.getBranchGrantLoginConfig()
    this.initComp()
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    checkPermission(data){
        const details = this.$store.getters.getTabPermission;
        if(details && details.length > 0){
          // if(details.includes(data)){
             return true
          // }else{
          //   return false
          // }
        }else{
          return false
        }
      },
    initComp(){
      let data = this.$store.getters.currentPlanDetail;
      this.activeSubscriptions = data || []

      // let checkMainPlan = data.filter( x => x.plan_code == 'V50')

      // if(checkMainPlan && checkMainPlan.length > 0){
      //   this.activeMainPlan =  true
      // }
      
    },
    checkCertificateRequestPermission(plan){
    let data = this.activeSubscriptions.filter( x => x.plan_code == 'V50' || x.plan_code == 'VY50')

      if(data && data.length > 0){
        return true
      }else{
        return false
      }
    },
    checkFeesPlanPermission(plan){
    let data = this.activeSubscriptions.filter( x => x.plan_code == 'V50' || x.plan_code == 'VFCP01' || x.plan_code == 'VY50')

      if(data && data.length > 0){
        return true
      }else{
        return false
      }
    },
    async updateFile(type, docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0];
        const fd = new FormData();
        fd.append(
          "doc",
          docFileDetails,
          docFileDetails.name,
          docFileDetails.actType
        );
        const response = await ViService.viPutOnly(
          `/document/uploadFileAccountSetting/${userData.userDetails.account._id}/${type}`,
          fd,
          userData.token
        );
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     this.$router.push({ path: data });
    },
    async removeFile(type, key) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/document/removeAccountSettingConfig/${userData.userDetails.account._id}/${type}/${key}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success("Deleted Successfully");
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    closeFilePopUp() {
      this.$bvModal.hide("viewSealFile");
      this.$bvModal.hide("viewSignFile");
    },

    async updateTriggerMail() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          isMailTrigger: this.isMailTrigger,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateIsClassRanking() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          isClassRanking: this.isClassRanking,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateIsClassSectionRanking() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          isClassSectionRanking: this.isClassSectionRanking,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    
    
    async updateSenderMail() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          senderMail: this.editsenderMail,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeSenderMailPopUp();
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateSupportMail() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          supportMail: this.editsupportMail,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeSupportMailPopUp();
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateCurrentAcademicYear() {
      let userData = secureUI.sessionGet("user");
      
      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          currentAcademicYear: this.currentAcademicYear,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          let data = this.academicYearList.find(
            (x) => x._id == this.currentAcademicYear
          );
          const jsonString = JSON.stringify(data);

          localStorage.setItem("currentAcademicYear",data._id);

          this.$store.dispatch("setCurrentAcademicYear", data);

          this.closeupdateCurrentAcademicyr();
          this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    openWorkingdays() {
      let values = [ ...this.workingDayList ]
      this.updateWorkingList = [ ...values ]
      this.$bvModal.show("editWorkingDays");
    },
    closeWorkingDay() {
      this.updateWorkingList = []
      this.$bvModal.hide("editWorkingDays");
    },
    closedepartmentidPopUp() {
      this.$bvModal.hide("crtfeeplan");
      this.$bvModal.hide("editeacherAutoNum");
      this.$bvModal.hide("editstudentAutoNum");
      this.$bvModal.hide("edifeeTypeAutoNum");
      this.$bvModal.hide("editInvoiceAutoNum");
    },
    closeTermsAndCondition() {
      this.$bvModal.hide("editTermsAndCondition");
    },
    closeSenderMailPopUp() {
      (this.editsenderMail = ""), this.$bvModal.hide("updateSenderID");
    },
    closeSupportMailPopUp() {
      (this.editsupportMail = ""), this.$bvModal.hide("updateSupportID");
    },
    closeupdateCurrentAcademicyr() {
      this.$bvModal.hide("updateCurrentAcademicyr");
    },
    
    async getPlanDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          this.department.prefix = details.departmentAutoNumFormat.prefix;
          this.department.format = details.departmentAutoNumFormat.format;
          this.department.start = details.departmentAutoNumFormat.start;

          if (details.teacherIdAutoNumFormat) {
            this.teacher.prefix = details.teacherIdAutoNumFormat.prefix;
            this.teacher.format = details.teacherIdAutoNumFormat.format;
            this.teacher.start = details.teacherIdAutoNumFormat.start;
          }

          if (details.studentRollAutoNumFormat) {
            this.student.prefix = details.studentRollAutoNumFormat.prefix;
            this.student.format = details.studentRollAutoNumFormat.format;
            this.student.start = details.studentRollAutoNumFormat.start;
          }

          if (details.feeTypeIdAutoNumFormat) {
            this.feeType.prefix = details.feeTypeIdAutoNumFormat.prefix;
            this.feeType.format = details.feeTypeIdAutoNumFormat.format;
            this.feeType.start = details.feeTypeIdAutoNumFormat.start;
          }

          if (details.invoiceIdAutoNumFormat) {
            this.invoice.prefix = details.invoiceIdAutoNumFormat.prefix;
            this.invoice.format = details.invoiceIdAutoNumFormat.format;
            this.invoice.start = details.invoiceIdAutoNumFormat.start;
          }

          if (details.tcNumFormat) {
            this.transfer.prefix = details.tcNumFormat.prefix;
            this.transfer.format = details.tcNumFormat.format;
            this.transfer.start = details.tcNumFormat.start;
          }
          if (details.ccNumFormat) {
            this.conduct.prefix = details.ccNumFormat.prefix;
            this.conduct.format = details.ccNumFormat.format;
            this.conduct.start = details.ccNumFormat.start;
          }
          if (details.bcNumFormat) {
            this.bonafide.prefix = details.bcNumFormat.prefix;
            this.bonafide.format = details.bcNumFormat.format;
            this.bonafide.start = details.bcNumFormat.start;
          }

          if (details.schoolSeal) {
            this.schoolSeal.path = details.schoolSeal.path;
            this.schoolSeal.key = details.schoolSeal.key;
          }

          if (details.signature) {
            this.signature.path = details.signature.path;
            this.signature.key = details.signature.key;
          }

          if (details.workingDayList) {
            this.workingDayList = details.workingDayList || []
          }
          

          this.isMailTrigger = details.isMailTrigger || false;
          this.isClassRanking = details.isClassRanking || false,
          this.isClassSectionRanking = details.isClassSectionRanking || false,
          this.termsAndConditionDescription =
            details.termsAndConditionDescription || "";
          this.duration = details.grandLoginNoOfDays
            ? details.grandLoginNoOfDays
            : "";
          this.isEnableGrantLoginAccess = details.isGrandLoginActive
            ? details.isGrandLoginActive
            : false;
          (this.senderMail = details.senderMail || ""),
            (this.supportMail = details.supportMail || details.email);
          this.currentAcademicYear = details.currentAcademicYear || null;
          (this.editsupportMail = details.supportMail || details.email),
            (this.editsenderMail = details.senderMail || "");
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    showValues(){
      let days = [...this.workingDayList]
      const filteredObjects = days.filter(obj => obj.isselected);
      if(filteredObjects.length){
        const selectedValues = filteredObjects.map(obj => {
        return obj.name;
      });
      return selectedValues.join(', ')
      }else{
        return '';
      }
      
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          this.accountSettingId = details._id;
          this.department.prefix = details.departmentAutoNumFormat.prefix;
          this.department.format = details.departmentAutoNumFormat.format;
          this.department.start = details.departmentAutoNumFormat.start;

          if (details.teacherIdAutoNumFormat) {
            this.teacher.prefix = details.teacherIdAutoNumFormat.prefix;
            this.teacher.format = details.teacherIdAutoNumFormat.format;
            this.teacher.start = details.teacherIdAutoNumFormat.start;
          }

          if (details.studentRollAutoNumFormat) {
            this.student.prefix = details.studentRollAutoNumFormat.prefix;
            this.student.format = details.studentRollAutoNumFormat.format;
            this.student.start = details.studentRollAutoNumFormat.start;
          }

          if (details.feeTypeIdAutoNumFormat) {
            this.feeType.prefix = details.feeTypeIdAutoNumFormat.prefix;
            this.feeType.format = details.feeTypeIdAutoNumFormat.format;
            this.feeType.start = details.feeTypeIdAutoNumFormat.start;
          }

          if (details.invoiceIdAutoNumFormat) {
            this.invoice.prefix = details.invoiceIdAutoNumFormat.prefix;
            this.invoice.format = details.invoiceIdAutoNumFormat.format;
            this.invoice.start = details.invoiceIdAutoNumFormat.start;
          }

          if (details.tcNumFormat) {
            this.transfer.prefix = details.tcNumFormat.prefix;
            this.transfer.format = details.tcNumFormat.format;
            this.transfer.start = details.tcNumFormat.start;
          }
          if (details.ccNumFormat) {
            this.conduct.prefix = details.ccNumFormat.prefix;
            this.conduct.format = details.ccNumFormat.format;
            this.conduct.start = details.ccNumFormat.start;
          }
          if (details.bcNumFormat) {
            this.bonafide.prefix = details.bcNumFormat.prefix;
            this.bonafide.format = details.bcNumFormat.format;
            this.bonafide.start = details.bcNumFormat.start;
          }

          if (details.schoolSeal) {
            this.schoolSeal.path = details.schoolSeal.path || "";
            this.schoolSeal.key = details.schoolSeal.key || "";
          }

          if (details.signature) {
            this.signature.path = details.signature.path || "";
            this.signature.key = details.signature.key || "";
          }

          if (details.workingDayList) {
            this.workingDayList = details.workingDayList || []

            const filteredObjects = details.workingDayList.filter(obj => obj.isselected);

            if(filteredObjects.length){
              const selectedValues = filteredObjects.map(obj => {
              return obj.name;
            });
            this.showWorkingDays = selectedValues.join(', ')
            //return selectedValues.join(', ')
          }
        }

          this.isMailTrigger = details.isMailTrigger || false;
          this.isClassRanking = details.isClassRanking || false,
          this.isClassSectionRanking = details.isClassSectionRanking || false,

          this.termsAndConditionDescription =
            details.termsAndConditionDescription || "";
          // this.duration = details.grandLoginNoOfDays
          //   ? details.grandLoginNoOfDays
          //   : "";
          // this.isEnableGrantLoginAccess = details.isGrandLoginActive
          //   ? details.isGrandLoginActive
          //   : false;
          (this.senderMail = details.senderMail || ""),
            (this.supportMail = details.supportMail || details.email);
          this.currentAcademicYear = details.currentAcademicYear || null;
          (this.editsupportMail = details.supportMail || details.email),
            (this.editsenderMail = details.senderMail || "");
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async getBranchGrantLoginConfig() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getBranchGrantLoginConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          this.duration = details.grandLoginNoOfDays ? details.grandLoginNoOfDays : "";
          this.isEnableGrantLoginAccess = details.isGrandLoginActive ? details.isGrandLoginActive : false;
         
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    

    async removeGrandLogin() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/account/removeGrantLoginAccess?accountId=${userData.userDetails.account._id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getAccountDetails();
          this.getBranchGrantLoginConfig()
          this.duration = "";
          this.isEnableGrantLoginAccess = false;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async saveTermsAndCondition() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          termsAndConditionDescription: this.termsAndConditionDescription,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.closeTermsAndCondition();
          this.getAccountDetails();
          this.getBranchGrantLoginConfig()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async saveWorkingDays() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          workingDayList: this.updateWorkingList,
          _id: this.accountSettingId,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.closeWorkingDay();
          this.getAccountDetails();
          this.getBranchGrantLoginConfig()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    
    async updateDepartmentConfig(type) {
      let isFormValid = false;

      if (type == "department") {
        await this.$validator.validateAll("departConfig").then((result) => {
          isFormValid = result;
        });
      }

      if (type == "teacher") {
        await this.$validator.validateAll("teacherConfig").then((result) => {
          isFormValid = result;
        });
      }

      if (type == "feeType") {
        await this.$validator.validateAll("feeConfig").then((result) => {
          isFormValid = result;
        });
      }

      if (type == "student") {
        await this.$validator.validateAll("studentConfig").then((result) => {
          isFormValid = result;
        });
      }
      if (type == "TCrequest") {
        await this.$validator.validateAll("tcConfig").then((result) => {
          isFormValid = result;
        });
      }
      if (type == "CCrequest") {
        await this.$validator.validateAll("CCconfig").then((result) => {
          isFormValid = result;
        });
      }
      if (type == "BCrequest") {
        await this.$validator.validateAll("BCconfig").then((result) => {
          isFormValid = result;
        });
      }

      if (type == "invoice") {
        await this.$validator
          .validateAll("editInvoiceAutoNumfig")
          .then((result) => {
            isFormValid = result;
          });
      }

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          let obj = { _id: this.accountSettingId };

          if (type == "department") {
            obj["departmentAutoNumFormat"] = {
              prefix: this.department.prefix,
              format: this.department.format,
              start: Number(this.department.start),
            };
          }

          if (type == "teacher") {
            obj["teacherIdAutoNumFormat"] = {
              prefix: this.teacher.prefix,
              format: this.teacher.format,
              start: Number(this.teacher.start),
            };
          }
          if (type == "TCrequest") {
            obj["tcNumFormat"] = {
              prefix: this.transfer.prefix,
              format: this.transfer.format,
              start: Number(this.transfer.start),
            };
          }
          if (type == "CCrequest") {
            obj["ccNumFormat"] = {
              prefix: this.conduct.prefix,
              format: this.conduct.format,
              start: Number(this.conduct.start),
            };
          }
          if (type == "BCrequest") {
            obj["bcNumFormat"] = {
              prefix: this.bonafide.prefix,
              format: this.bonafide.format,
              start: Number(this.bonafide.start),
            };
          }
          if (type == "student") {
            obj["studentRollAutoNumFormat"] = {
              prefix: this.student.prefix,
              format: this.student.format,
              start: Number(this.student.start),
            };
          }

          if (type == "feeType") {
            obj["feeTypeIdAutoNumFormat"] = {
              prefix: this.feeType.prefix,
              format: this.feeType.format,
              start: Number(this.feeType.start),
            };
          }

          if (type == "invoice") {
            obj["invoiceIdAutoNumFormat"] = {
              prefix: this.invoice.prefix,
              format: this.invoice.format,
              start: Number(this.invoice.start),
            };
          }

          const response = await ViService.viXPut(
            "/department/updateDepartmentConfig",
            obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.$bvModal.hide("crtfeeplan");
            this.$bvModal.hide("editeacherAutoNum");

            this.$bvModal.hide("BCAutoNum");
            this.$bvModal.hide("CCAutoNum");
            this.$bvModal.hide("TCAutoNum");

            this.$bvModal.hide("editstudentAutoNum");
            this.$bvModal.hide("edifeeTypeAutoNum");
            this.$bvModal.hide("editInvoiceAutoNum");
            this.getAccountDetails();
            this.getBranchGrantLoginConfig()
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },

    async updateGrandLogin() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let requestBody = {
          expireDay: this.duration,
          accountId: userData.userDetails.account._id,
          branchId: userData.userDetails.branch._id
        };
        const response = await ViService.viXPost(
          `/user/grantLoginAccess/${userData.userDetails._id}`,
          requestBody,
          userData.token
        );
        if (response.isSuccess) {
          this.getAccountDetails();
          this.getBranchGrantLoginConfig()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
  },
  components: {
    ViSpinner,
    "plan-comp": () => import("../../views/ViPlanDetails.vue"),
    holiday: () => import("../Admin/ViHoliday.vue"),
  },
};
</script>
